import React, { useEffect, useState } from "react";
import { useMqttState, useSubscription } from 'mqtt-react-hooks';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import './App.css';

function App() {
  const url = new URL(window.location.href);
  const queryString = new URLSearchParams(url.search);
  const mode = queryString.get('mode');

  const [currentImgUrl, setCurrentImgUrl] = useState("");
  const [nextImgUrl, setNextImgUrl] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const { connectionStatus } = useMqttState();
  const { message } = useSubscription(['@topic/img']);

  useEffect(() => {
    if (message?.topic === '@topic/img') {
      setIsLoaded(false);
      const image = new Image();
      image.src = message?.message;
      image.onload = () => {
        setNextImgUrl(message?.message);
        setIsLoaded(true);
      };
    }
  }, [message]);

  useEffect(() => {
    if (mode === "0") {
      setIsLoaded(false);
      const defaultImage = new Image();
      defaultImage.src = "http://bonkab.com/ppt/Dataflow_Scene3.png";
      defaultImage.onload = () => {
        setNextImgUrl(defaultImage.src);
        setIsLoaded(true);
      };
    }
  }, [mode]);

  useEffect(() => {
    if (isLoaded && nextImgUrl) {
      setIsFading(true);
      const timeout = setTimeout(() => {
        setCurrentImgUrl(nextImgUrl);
        setIsFading(false);
      }, 400); // Match this duration with the CSS transition duration
      return () => clearTimeout(timeout);
    }
  }, [isLoaded, nextImgUrl]);

  const debug = true;

  return (
    <>
      {debug && (
        <Box sx={{ position: "absolute", top: 0, left: 4, zIndex: 'tooltip' }}>
          <Typography variant="h6" component="div" color="red">
            {currentImgUrl}
          </Typography>
        </Box>
      )}
      <Box
        className="image-viewer"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "#000000aa",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="image-container">
          <img
            src={currentImgUrl}
            alt="current"
            className={isFading ? 'hidden' : 'visible'}
          />
          <img
            src={nextImgUrl}
            alt="next"
            className={isFading ? 'visible' : 'hidden'}
          />
        </div>
      </Box>
    </>
  );
}

export default App;
